import config from '../config';
import { isServer } from '../utils/isServer';

type LPRequestInit<ReqBody = unknown> = {
  throwsNotOk?: boolean;
  fetcher?: typeof window.fetch;
  token?: string | null;
  bodyObj?: ReqBody;
};

function doubleSlashReplace(input: string) {
  return input.replace(/\/\//g, '/');
}

function joinUrlWithPath(input: string | URL, baseUrl: string): URL {
  // The base url might include part of a path (e.g. /api/v1). Parse it to
  // reduce ambiguity during string concat.

  // NOTE: URL constructor ignores any path segments in `base`!

  const baseUrlParsed = new URL(baseUrl);
  let parsed;

  try {
    parsed = new URL(input);
    // input is absolute
    parsed.pathname = doubleSlashReplace(
      baseUrlParsed.pathname + parsed.pathname
    );
    parsed.hostname = baseUrlParsed.hostname;
  } catch (err) {
    // input is relative
    parsed = new URL(baseUrl);
    parsed.pathname = doubleSlashReplace(baseUrlParsed.pathname + input);
    parsed.hostname = baseUrlParsed.hostname;
  }

  return parsed;
}

export async function publicFetch<ReqBody = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit & LPRequestInit<ReqBody>
) {
  const throwsNotOk = init?.throwsNotOk ?? false;
  const fetcher = init?.fetcher ?? globalThis.fetch;

  if (init?.token) {
    init.headers = { ...init.headers, Authorization: `Bearer ${init.token}` };
    delete init.token;
  }

  if (init?.bodyObj) {
    init.body = JSON.stringify(init.bodyObj);
  }

  const res = await fetcher(input, init);
  if (throwsNotOk && !res.ok) {
    throw new Error(res.statusText, { cause: res });
  }
  return res;
}

export function isErrorCausedByResponse(
  err: unknown
): err is Error & { cause: Response } {
  return (
    typeof err === 'object' &&
    err !== null &&
    'cause' in err &&
    err.cause instanceof Response
  );
}

/**
 * A very lightweight fetch client, with automatic baseUrl and notOk throwing.
 * Using the handwritten ApiClient instance pulls in axios and _all_ request
 * handlers (they are defined as classes, impossible to tree-shake an instance),
 * which is somewhere around 40K. The generated client's request handling is
 * also ~40K due to a similar pattern of including all handles, so this is an
 * attempt to be as lightweight as possible.
 */
export async function publicFetchAPIService<Res, ReqBody = unknown>(
  input: RequestInfo | URL,
  init?: RequestInit & LPRequestInit<ReqBody>
): Promise<{ json: Res; res: Response }> {
  init = init ?? {};
  if (init.throwsNotOk === undefined) init.throwsNotOk = true;
  const res = await publicFetch(input, init);
  const json: Res = await res.json();
  return { json, res };
}

export class APIServiceURL extends URL {
  constructor(
    url: string | URL,
    base = isServer() ? config.api.internalBaseUrl : config.api.baseUrl
  ) {
    super(joinUrlWithPath(url, base));
  }
}

export class SiteBaseURL extends URL {
  constructor(url = config.app.baseUrl) {
    super(url);
  }
}
